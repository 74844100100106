import Seo from 'modules/app/components/Seo';
import { TermsOfServicePage } from 'modules/legal';
import React from 'react';
import { CookieConsent, PublicLayout } from '../modules/app';
import useSiteMetadata from 'modules/app/graphql/useSiteMetadata';

const TermsOfService: React.FC<WebPageProps> = ({ pageContext }) => {
  const { siteUrl } = useSiteMetadata();
  const { intl } = pageContext;
  const path = `${siteUrl}/${intl.language}${intl.originalPath}`;

  return (
    <>
      <Seo
        url={path}
        title="Terms and Conditions - Roango"
        description="Information related to responsibilities, rights, privacy, subscriptions, information usage, and cancellation of service"
        image={`${siteUrl}/roango-share-image.png`}
      />

      <PublicLayout>
        <TermsOfServicePage />
      </PublicLayout>

      <CookieConsent />
    </>
  );
};

export default TermsOfService;
